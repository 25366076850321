<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permissionAddTopic">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permissionAddTopic">
      <b-col>
        <b-container>
          <b-row class="mb-4" v-if="!finalstep">
            <b-col>
              <h4>Adding new regulatory topic</h4>
              <div class="mb-2"><strong>Title</strong></div>
                <b-form-input   id="titleInput"
                                v-model="topic.name"
                                placeholder="Regulation title"
                                required
                                class="mb-2">
                </b-form-input>
              <div class="mb-2"><strong>Headlines (markdown)</strong></div>
                <b-form-textarea id="detailsInput"
                                v-model="topic.comments"
                                placeholder="Regulation details"
                                required
                                rows=4
                                class="mb-2">
                </b-form-textarea>
              <div class="mb-2"><strong>Abstract (markdown)</strong></div>
              <b-form-textarea id="abstractInput"
                                v-model="topic.abstract"
                                placeholder="Regulation abstract"
                                required
                                rows=20
                                class="mb-2">
              </b-form-textarea>
              <span v-if="!saving">
                <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="save">Save</b-button>
                <b-button variant="outline-primary" size="sm" :to="{ name: 'Topics' }">Cancel</b-button>
              </span>
              <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="finalstep">
            <b-col>
              <h2>Regulatory topic created</h2>
              <h3>{{topic.name}}</h3>
              <div><markdown :content="topic.comments" /></div>
              <div><markdown :content="topic.abstract" /></div>
              <b-button variant="primary" size="lg" :to="{ name: 'TopicEdit', params: { id: topic.id }}">Continue to edit details</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '../libs/accesscontrol'
import Markdown from '@/components/Markdown.vue'

export default {
  name: 'TopicAdd',
  beforeDestroy () {},
  components: {
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.topic.name && this.topic.comments
    }
  },
  created: function () {
    this.$stat.log({ page: 'create topic', action: 'open create topic' })
    this.permissionAddTopic = ac.can(this.user.acgroups).createAny('topic').granted
  },
  data () {
    return {
      finalstep: false,
      permissionAddTopic: false,
      saving: false,
      topic: {
        abstract: '',
        name: null,
        comments: null
      }
    }
  },
  methods: {
    save: async function () {
      this.$logger.debug(`save start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/topic`
        let params = {
          body: {
            topic: this.topic
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$stat.log({ page: 'create topic', action: 'save new topic' })
        this.$logger.debug('saved OK:', response)
        this.topic = response
        this.finalstep = true
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  },
  mounted () {},
  watch: {}
}
</script>

<style>
</style>
